<template>
  <div class="contractdetail">
    <el-row :gutter="2">
      <el-col :span="24">
        <div class="grid-content">
          <span class="label">合同编号: </span>
          <span class="value">{{ contractDetail.contract_code }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">对方公司名称: </span>
          <span class="value">{{ contractDetail.yifang }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">施工地点: </span>
          <span class="value">{{ contractDetail.projectPlace }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">联系人: </span>
          <span class="value">{{ contractDetail.lianxiren }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">电话: </span>
          <span class="value">{{ contractDetail.contact_phone }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">我方公司名称: </span>
          <span class="value">{{ contractDetail.jiafang }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">负责人: </span>
          <span class="value">{{ contractDetail.fuzeren }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">是否结单: </span>
          <el-tag
            effect="dark"
            size="mini"
            :type="contractDetail.is_jiedan ? 'primary' : 'success'"
            >{{ !contractDetail.is_jiedan ? '未结单' : '已结单' }}</el-tag
          >
          <el-button @click="updateJiedan()" size="mini" type="text" plain
            >改变结单状态</el-button
          >
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <el-button @click="transferZxManage()" size="small" type="success"
            >转入合同执行管理</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label" style="float:left">欠款金额: </span>
          <el-input
            type="number"
            step="0.01"
            v-model="contractDetail.qiankuan_amount"
            size="mini"
            style="width: 45%;"
          ></el-input>
          <el-button
            @click="updateQiankuan(contractDetail.qiankuan_amount)"
            size="mini"
            type="primary"
            plain
            >修改</el-button
          >
        </div>
      </el-col>

      <el-col :span="18">
        <div class="grid-content">
          <span class="label">未返还材料情况: </span>
          <el-input
            v-model="contractDetail.notreturnmat"
            size="mini"
            style="width: 85%;"
          ></el-input>
          <el-button
            @click="updateNotreturnmat(contractDetail.notreturnmat)"
            size="mini"
            type="primary"
            plain
            >修改</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    contractDetail: Object,
    userType: Object
  },
  data() {
    return {}
  },
  methods: {
    updateQiankuan(qiankuan_amount) {
      this.$emit('updateQiankuan', qiankuan_amount)
    },

    updateNotreturnmat(notreturnmat) {
      this.$emit('updateNotreturnmat', notreturnmat)
    },

    updateJiedan() {
      this.$emit('updateJiedan')
    },
    transferZxManage() {
      this.$emit('transferZxManage')
    }
  }
}
</script>
