<template>
  <div id="container">
    <!-- dialogs -->
    <!-- 合同回款 增加 -->
    <el-dialog
      width="700px"
      :title="operateType === 'add' ? '新增合同回款' : '修改合同回款'"
      :visible.sync="htHuikuan_isShow"
      :close-on-click-modal="false"
    >
      <HtFawuHuikuanForm
        ref="fawuhuikuanForm"
        :form="fawuhuikuanForm"
        @submitForm="submitHuikuanForm"
        @resetForm="resetHuikuanForm"
      ></HtFawuHuikuanForm>
    </el-dialog>
    <!-- 催收 增加 -->
    <el-dialog
      width="700px"
      :title="cuishouOperateType === 'add' ? '新增催收信息' : '修改催收信息'"
      :visible.sync="htCuishou_isShow"
      :close-on-click-modal="false"
    >
      <HtFawuCuishouForm
        ref="fawucuishouForm"
        :form="fawucuishouForm"
        @submitForm="submitCuishouForm"
        @resetForm="resetCuishouForm"
      ></HtFawuCuishouForm>
    </el-dialog>
    <!-- 诉讼 增加 -->
    <el-dialog
      width="700px"
      :title="susongOperateType === 'add' ? '新增诉讼信息' : '修改诉讼信息'"
      :visible.sync="htSusong_isShow"
      :close-on-click-modal="false"
    >
      <HtFawuSusongForm
        ref="fawususongForm"
        :form="fawususongForm"
        @submitForm="submitSusongForm"
        @resetForm="resetSusongForm"
      ></HtFawuSusongForm>
    </el-dialog>
    <!-- 执行 增加 -->
    <el-dialog
      width="700px"
      title="新增执行信息"
      :visible.sync="htZhixing_isShow"
      :close-on-click-modal="false"
    >
      <HtFawuZhixingForm
        ref="fawuzhixingForm"
        @submitForm="submitZhixingForm"
        @resetForm="resetZhixingForm"
      ></HtFawuZhixingForm>
    </el-dialog>

    <div id="main">
      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">一. 基本情况</div>
              <div>
                <el-button type="warning" @click="backList">返 回</el-button>
              </div>
            </div>
            <contractdetail-table
              :contractDetail="contractDetail"
              :userType="usertype"
              @updateQiankuan="updateQiankuan"
              @updateNotreturnmat="updateNotreturnmat"
              @updateJiedan="updateJiedan"
              @transferZxManage="transferZxManage"
            ></contractdetail-table>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">二. 回款情况</div>
              <div>
                <el-button type="primary" @click="addFawuhuikuan"
                  >+ 新增</el-button
                >
              </div>
            </div>
            <HtFawuHuikuanTable
              :userType="usertype"
              :tableData="fawuHuikuanTableData"
              @del="deleteFawuhuikuan"
              @edit="editFawuhuikuan"
            ></HtFawuHuikuanTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">三. 催收情况</div>
              <div>
                <el-button type="primary" @click="addFawucuishou"
                  >+ 新增</el-button
                >
              </div>
            </div>
            <HtFawuCuishouTable
              :userType="usertype"
              :tableData="fawuCuishouTableData"
              @del="deleteFawucuishou"
              @edit="editFawuCuishou"
            ></HtFawuCuishouTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">四. 诉讼情况</div>
              <div>
                <el-button type="primary" @click="addFawususong"
                  >+ 新增</el-button
                >
              </div>
            </div>
            <HtFawuSusongTable
              :userType="usertype"
              :tableData="fawuSusongTableData"
              @del="deleteFawususong"
              @edit="editFawususong"
            ></HtFawuSusongTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">五. 执行情况</div>
              <div>
                <el-button type="primary" @click="addFawuzhixing"
                  >+ 新增</el-button
                >
              </div>
            </div>
            <HtFawuZhixingTable
              :userType="usertype"
              :tableData="fawuZhixingTableData"
              @del="deleteFawuzhixing"
            ></HtFawuZhixingTable>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div class="block">
            <div class="block_header">
              <div class="block_title">备注</div>
              <div>
                <el-button type="primary" @click="remark_readonly = false"
                  >可修改</el-button
                >
                <el-button
                  type="primary"
                  @click="saveRemark"
                  :disabled="remark_readonly"
                  >保 存</el-button
                >
              </div>
            </div>
            <el-input
              type="textarea"
              :rows="4"
              v-model="remark"
              :readonly="remark_readonly"
            ></el-input>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ContractdetailTable from './HtFawuContractDetailTable'
import HtFawuHuikuanTable from './HtFawuHuikuanTable'
import HtFawuHuikuanForm from './HtFawuHuikuanForm'
import HtFawuCuishouTable from './HtFawuCuishouTable'
import HtFawuCuishouForm from './HtFawuCuishouForm'
import HtFawuSusongTable from './HtFawuSusongTable'
import HtFawuSusongForm from './HtFawuSusongForm'
import HtFawuZhixingTable from './HtFawuZhixingTable'
import HtFawuZhixingForm from './HtFawuZhixingForm'
import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      currentContract: '',
      contractDetail: {},
      fawuHuikuanTableData: [],
      operateType: 'add',
      htHuikuan_isShow: false,
      fawuhuikuanForm: {},
      fawuCuishouTableData: [],
      cuishouOperateType: 'add',
      htCuishou_isShow: false,
      fawucuishouForm: {},
      fawuSusongTableData: [],
      susongOperateType: 'add',
      htSusong_isShow: false,
      fawususongForm: {},
      fawuZhixingTableData: [],
      htZhixing_isShow: false,
      fawuZhixingForm: {},
      usertype: '',
      user_realname: '',
      remark: '',
      remark_readonly: true
    }
  },

  components: {
    ContractdetailTable,
    HtFawuHuikuanTable,
    HtFawuHuikuanForm,
    HtFawuCuishouTable,
    HtFawuCuishouForm,
    HtFawuSusongTable,
    HtFawuSusongForm,
    HtFawuZhixingTable,
    HtFawuZhixingForm
  },
  methods: {
    //返回合同列表页
    backList() {
      this.$router.push({ name: 'HtFawuManage', query: { from: 'detail' } })
    },
    //获取合同详情
    getContractDetail() {
      this.currentContract = this.$route.query.id
      console.log('contract_id:', this.currentContract)
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getFwHtContractDetail/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('getFwHtContractDetail', res.data)
          this.contractDetail = res.data
          this.remark = res.data.remark
          console.log('isJidan:', this.contractDetail)
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    /*
     *************修改欠款金额*************
     */
    updateQiankuan(qiankuan_amount) {
      this.$confirm('确定要修改欠款金额吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/updateQiankuan/', {
              params: {
                id: this.contractDetail.id,
                qiankuan_amount: qiankuan_amount
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
              this.getContractDetail()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
        })
    },

    /*
     *************修改为返还材料*************
     */
    updateNotreturnmat(notreturnmat) {
      this.$confirm('确定要修改未返材料吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/updateNotreturnmat/', {
              params: {
                id: this.contractDetail.id,
                notreturnmat: notreturnmat
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
              this.getContractDetail()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
        })
    },
    /*
     *************修改结单状态*************
     */
    updateJiedan() {
      let msg = this.contractDetail.is_jiedan
        ? '确定要改为未结单吗？'
        : '确定要改为已结单吗？'
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/updateJiedan/', {
              params: {
                id: this.contractDetail.id,
                isJiedan: this.contractDetail.is_jiedan ? 0 : 1
              }
            })
            .then(res => {
              console.log(res.data)
              this.contractDetail.is_jiedan = res.data.is_jiedan
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
              this.getContractDetail()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          })
        })
    },
    /*
     *************转入合同执行管理*************
     */
    transferZxManage() {
      this.$confirm('确定要将此合同转入合同执行管理吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/transferZxManage/', {
              params: {
                id: this.contractDetail.id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '转入合同执行成功，将返回到合同列表页面!'
              })
              this.backList()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消转入操作！'
          })
        })
    },

    /*
     ************回款*************
     */
    //获取回款情况
    getFawuhuikuan() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawuhuikuan/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawuhuikuan', res.data.data)
            this.fawuHuikuanTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(
                item.added_person_time,
                'yyyy-MM-dd hh:mm'
              )
              return item
            })
          } else {
            this.fawuHuikuanTableData = []
            console.log('回款信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增法务回款信息
    addFawuhuikuan() {
      this.fawuhuikuanForm = {}
      this.operateType = 'add'
      this.htHuikuan_isShow = true
    },
    //修改法务回款信息
    editFawuhuikuan(row) {
      this.operateType = 'edit'
      console.log('row before open editDlg:', row)
      row.huikuan_amount = Number(row.huikuan_amount)
      row.qiankuan_amount = Number(row.qiankuan_amount)
      this.fawuhuikuanForm = row
      this.htHuikuan_isShow = true
    },

    //删除法务回款信息
    deleteFawuhuikuan(row) {
      this.$confirm('此操作将永久删除此回款信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFawuhuikuan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getFawuhuikuan()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitHuikuanForm(formData) {
      this.fawuhuikuanForm = formData
      if (this.operateType === 'add') {
        //增加法务回款
        console.log('add fawuhuikuanForm:', this.fawuhuikuanForm)
        this.fawuhuikuanForm.contract = this.currentContract
        this.fawuhuikuanForm.added_person = this.user_realname
        this.fawuhuikuanForm.added_person_time = utils.getDateTime()
        this.$axios.post('/addFawuhuikuan/', this.fawuhuikuanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加回款信息成功!',
              duration: 2000
            })
            this.htHuikuan_isShow = false
            this.getFawuhuikuan()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      } else {
        // 修改法务回款
        this.$axios.put('/editFawuhuikuan/', this.fawuhuikuanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '修改回款信息成功!',
              duration: 2000
            })
            this.htHuikuan_isShow = false
            this.getFawuhuikuan()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      }
    },
    resetHuikuanForm() {
      this.htHuikuan_isShow = false
      this.fawuhuikuanForm = {}
    },

    /*
     ************催收*************
     */
    //获取催收情况
    getFawucuishou() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawucuishou/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawucuishou', res.data.data)
            this.fawuCuishouTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(
                item.added_person_time,
                'yyyy-MM-dd hh:mm'
              )
              return item
            })
          } else {
            this.fawuCuishouTableData = []
            console.log('催收信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增法务催收信息
    addFawucuishou() {
      this.fawucuishouForm = {}
      this.cuishouOperateType = 'add'
      this.htCuishou_isShow = true
    },
    //修改法务催收信息
    editFawuCuishou(row) {
      this.cuishouOperateType = 'edit'
      console.log('row before open editDlg:', row)
      this.fawucuishouForm = row
      this.htCuishou_isShow = true
    },

    //删除法务催收信息
    deleteFawucuishou(row) {
      this.$confirm('此操作将永久删除此催收信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFawucuishou/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getFawucuishou()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitCuishouForm(formData) {
      this.fawucuishouForm = formData
      if (this.cuishouOperateType === 'add') {
        //增加法务催收
        console.log('add fawucuishouForm:', this.fawucuishouForm)
        this.fawucuishouForm.contract = this.currentContract
        this.fawucuishouForm.added_person = this.user_realname
        this.fawucuishouForm.added_person_time = utils.getDateTime()
        this.$axios.post('/addFawucuishou/', this.fawucuishouForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加催收信息成功!',
              duration: 2000
            })
            this.htCuishou_isShow = false
            this.getFawucuishou()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      } else {
        // 修改法务催收
        this.$axios.put('/editFawucuishou/', this.fawucuishouForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '修改催收信息成功!',
              duration: 2000
            })
            this.htCuishou_isShow = false
            this.getFawucuishou()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      }
    },
    resetCuishouForm() {
      this.htCuishou_isShow = false
      this.fawucuishouForm = {}
    },

    /*
     ************诉讼*************
     */
    //获取诉讼情况
    getFawususong() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawususong/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawususong', res.data.data)
            this.fawuSusongTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(
                item.added_person_time,
                'yyyy-MM-dd hh:mm'
              )
              return item
            })
          } else {
            this.fawuSusongTableData = []
            console.log('诉讼信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增法务诉讼信息
    addFawususong() {
      this.fawususongForm = {}
      this.susongOperateType = 'add'
      this.htSusong_isShow = true
    },
    //修改法务诉讼信息
    editFawususong(row) {
      this.susongOperateType = 'edit'
      console.log('row before open editDlg:', row)
      this.fawususongForm = row
      this.htSusong_isShow = true
    },
    //删除法务诉讼信息
    deleteFawususong(row) {
      this.$confirm('此操作将永久删除此诉讼信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFawususong/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getFawususong()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitSusongForm(formData) {
      this.fawususongForm = formData
      if (this.susongOperateType === 'add') {
        //增加法务诉讼
        console.log('add fawususongForm:', this.fawususongForm)
        this.fawususongForm.contract = this.currentContract
        this.fawususongForm.added_person = this.user_realname
        this.fawususongForm.added_person_time = utils.getDateTime()
        this.$axios.post('/addFawususong/', this.fawususongForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加诉讼信息成功!',
              duration: 2000
            })
            this.htSusong_isShow = false
            this.getFawususong()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      } else {
        // 修改法务诉讼
        this.$axios.put('/editFawususong/', this.fawususongForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '修改诉讼信息成功!',
              duration: 2000
            })
            this.htSusong_isShow = false
            this.getFawususong()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      }
    },
    resetSusongForm() {
      this.htSusong_isShow = false
      this.fawususongForm = {}
    },

    /*
     ************执行*************
     */
    //获取执行情况
    getFawuzhixing() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFawuzhixing/'
      this.$axios
        .get(api, {
          params: {
            contract_id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('getFawuzhixing', res.data.data)
            this.fawuZhixingTableData = res.data.data.map(item => {
              item.added_person_time = utils.formatDateTime(
                item.added_person_time,
                'yyyy-MM-dd hh:mm'
              )
              return item
            })
          } else {
            this.fawuZhixingTableData = []
            console.log('执行信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增法务执行信息
    addFawuzhixing() {
      this.fawuzhixingForm = {}
      this.htZhixing_isShow = true
    },

    //删除法务执行信息
    deleteFawuzhixing(row) {
      this.$confirm('此操作将永久删除此执行信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFawuzhixing/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getFawuzhixing()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    submitZhixingForm(formData) {
      this.fawuzhixingForm = formData
      //增加法务执行
      console.log('add fawuzhixingForm:', this.fawuzhixingForm)
      this.fawuzhixingForm.contract = this.currentContract
      this.fawuzhixingForm.added_person = this.user_realname
      this.fawuzhixingForm.added_person_time = utils.getDateTime()
      this.$axios.post('/addFawuzhixing/', this.fawuzhixingForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加执行信息成功!',
            duration: 2000
          })
          this.htZhixing_isShow = false
          this.getFawuzhixing()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetZhixingForm() {
      this.htZhixing_isShow = false
      this.fawuzhixingForm = {}
    },

    saveRemark() {
      this.$confirm('确认要修改备注吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/UpdateFawuContract/', {
              params: {
                contract_id: this.currentContract,
                remark: this.remark
              }
            })
            .then(res => {
              console.log(res.data)
              this.remark_readonly = true
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
            })
        })
        .catch(() => {
          this.remark_readonly = true
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
  created() {
    this.getContractDetail()
    this.getFawuhuikuan()
    this.getFawucuishou()
    this.getFawususong()
    this.getFawuzhixing()
  }
}
</script>
<style scoped>
#container {
  width: 100%;
  /* padding: 10px; */
  background-color: rgb(238, 240, 245);
}
.block_title {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(142, 192, 201);
  font-size: 15px;
  color: white;
}

.block_header {
  display: flex;
  justify-content: space-between;
}

.block {
  width: 100%;
  background-color: rgb(142, 192, 201);
  margin-bottom: 20px;
}
</style>
<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
