<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="100px"
    size="small"
  >
    <el-row>
      <el-col :span="24">
        <el-form-item label="回款日期" prop="huikuan_date">
          <el-date-picker
            v-model="form.huikuan_date"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择回款日期"
            :picker-options="pickerOptions1"
          ></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="回款金额" prop="huikuan_amount">
          <el-input
            type="number"
            step="0.01"
            v-model="form.huikuan_amount"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="尚欠款" prop="qiankuan_amount">
          <el-input
            type="number"
            step="0.01"
            v-model="form.qiankuan_amount"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="已返材料" prop="yifan_cailiao">
          <el-input
            type="textarea"
            :rows="4"
            v-model="form.yifan_cailiao"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="未返材料" prop="weifan_cailiao">
          <el-input
            type="textarea"
            :rows="4"
            v-model="form.weifan_cailiao"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    form: Object
  },
  data() {
    return {
      loading: false,
      rules: {
        huikuan_amount: [
          {
            required: true,
            message: '请填写正确的金额,保留两位小数',
            pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
          }
        ],
        qiankuan_amount: [
          {
            required: true,
            message: '请填写正确的金额,保留两位小数',
            pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
          }
        ],
        yifan_cailiao: [
          { required: true, message: '请填写已返材料', trigger: 'blur' }
        ],
        weifan_cailiao: [
          { required: true, message: '请填写未返材料', trigger: 'blur' }
        ],
        huikuan_date: [
          { required: true, message: '请选择回款日期', trigger: 'blur' }
        ]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.form)
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped></style>
